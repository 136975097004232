.App {
  text-align: center;
}

.logo {
    width: 162px;
    height: 63px;
    pointer-events: none;
    background: url("./logo.png") no-repeat;
    margin: 0 25px;
}

.App-header {
    background-color: #fff;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    box-shadow: 0 16px 32px rgba(0,0,0,0.04);
}

.header-nav {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    border: none;
}

/* src/App.css */
.App-header .ant-menu-horizontal > .ant-menu-item {
    padding: 0 15px;
    border-bottom: 2px solid transparent;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
}

.AppHeader-user {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    min-width: 100px;
}

.AppHeader-profile {
    width: 30px;
    height: 30px;
}
